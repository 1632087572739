/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveBreaks, resolveMeta } from "@dataResolvers";
import { Contact as PageTemplate } from "@pageTemplates";

export const query = graphql`
query {
  craft {
    entry(section: "contact") {
      ... on Craft_contact_contact_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Hero
        heroHeading
        heroDescription
        # Contact Details
        copy0
        copy1
      }
    }
  }
}
`;

const previewQuery = `
query {
  craft {
    entry(section: "contact") {
      ... on Craft_contact_contact_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Hero
        heroHeading
        heroDescription
        # Contact Details
        copy0
        copy1
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    uid,
    url,
    slug,
    metaTitle,
    metaDescription,
    metaImage,
    // Heading
    heroHeading,
    heroDescription,
    // Contact Details
    copy0,
    copy1,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: resolveBreaks(heroHeading),
      subhead: heroDescription,
    },
    contact: {
      info: copy0,
      mail: copy1,
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
